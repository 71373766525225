.btn {
  display: inline-block;
  padding: 6px 15px;
  background: $red;
  border-radius: 3px;
  font-size: 18px;
  color: $white;
  text-align: center;
  .icon {
    position: relative;
    top: -1px;
    fill: $white;
    &-chevron-down {
      margin-left: 10px;
      font-size: 6px;
      vertical-align: middle; } }
  &_stroke {
    background: $white;
    border: 1px solid $red;
    color: $red;
    .icon {
      fill: $red; } } }

