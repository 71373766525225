.menu {
  position: relative;
  background: $black;
  z-index: 15;
  @include m {
    display: none; }
  &__center {
    padding: 0 0 0 7px;
    overflow: hidden;
    height: 45px;
    @include r(1420) {
      padding: 0 20px 0 7px; } }
  &__wrap {
    display: block;
    width: calc(100% - 95px);
    height: 45px;
    &.end {
      .owl {
        &-next {
          display: none; } } } }
  &__group,
  &__item {
    display: block;
    vertical-align: top; }
  &__group {
    width: 100%;
    box-sizing: border-box; }
  &__item {
    cursor: default;
    float: left; }
  &__item.active {
    background: $red; }
  &__item.active &__drop {
    display: block; }
  &__type {
    display: flex;
    height: 45px;
    padding: 0 18px;
    font-size: 16px;
    white-space: nowrap;
    color: $white;
    align-items: center;
    .icon {
      margin-left: 8px;
      font-size: 6px;
      fill: $white; } }
  &__drop {
    display: none;
    position: absolute;
    top: 45px;
    left: 0;
    right: 0;
    padding: 20px 0 0px;
    background: $white;
    box-shadow: 0 5px 0 rgba(0, 0, 0, 0.16); }
  &__drop &__center {
      font-size: 0; }
  &__col {
    display: inline-block;
    width: 20%;
    padding: 0 20px 30px 0;
    box-sizing: border-box;
    vertical-align: top; }
  &__category,
  &__link {
    margin-bottom: 5px;
    font-size: 12px; }
  &__category {
    color: $red; }
  &__link {
    display: table;
    color: $black; }
  &__link:hover {
    text-decoration: underline; }
  .owl {
    &-nav {
      display: flex;
      position: absolute;
      top: 0;
      right: -95px;
      background: $black;
      z-index: 2; }
    &-prev,
    &-next {
      width: 45px;
      height: 45px;
      background: rgba($white,.25);
      border-radius: 50%;
      font-size: 0;
      transition: background .2s;
      &:hover {
        background: rgba($white,.5); }
      &.disabled {
        visibility: hidden;
        display: none; }
      .icon {
        font-size: 7px;
        fill: $white; } }
    &-prev {
      transform: rotate(-180deg); }
    &-next {
      margin-left: 3px; } } }

.hide {
  display: none; }
