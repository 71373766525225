@mixin m {
  @media only screen and (max-width: "740px") {
    @content; } }
@mixin s {
  @media only screen and (max-width: "370px") {
    @content; } }
@mixin d {
  @media only screen and (min-width: "741px") {
    @content; } }

// +font(, )
// =fl
//   font-family: '', serif

@mixin f {
  font-family: 'proxima-nova', Arial, sans-serif; }

$black: #000;
$white: #fff;

$red: #bd0217;

$border: #dadada;

$c82: #828282;


