.icon-arrow-menu-down {
  width: 1.5em;
  height: 1em;
  fill: #000;
}
.icon-arrow-menu {
  width: 2.25em;
  height: 1em;
  fill: #000;
}
.icon-arrow-right {
  width: 0.67em;
  height: 1em;
  fill: #000;
}
.icon-chevron-down {
  width: 1.75em;
  height: 1em;
  fill: #000;
}
.icon-chevron-right {
  width: 0.57em;
  height: 1em;
  fill: #000;
}
.icon-close {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-m-left {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-m-right {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-next-hover {
  width: 0.18em;
  height: 1em;
  fill: #000;
}
.icon-next {
  width: 0.17em;
  height: 1em;
  fill: #000;
}
.icon-phone {
  width: 0.91em;
  height: 1em;
  fill: initial;
}
.icon-cart {
    width: 1em;
    height: 1em;
    fill: initial;
}
.icon-prev-hover {
  width: 0.18em;
  height: 1em;
  fill: #000;
}
.icon-prev {
  width: 0.17em;
  height: 1em;
  fill: #000;
}
.icon-search {
  width: 1em;
  height: 1em;
  fill: #000;
}
.icon-star {
  width: 1em;
  height: 1em;
  fill: #38447E;
}
