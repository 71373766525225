﻿.m-hide {
  @include m {
    display: none; } }
.m-show {
  display: none;
  @include m {
    display: block; } }

.m-footer {
  display: none;
  padding: 25px 0 30px;
  background: $black;
  @include m {
    display: block; }
  &__nav {
    display: flex;
    margin-bottom: 25px;
    justify-content: space-around; }
  &__link {
    padding: 0 10px;
    font-size: 17px;
    color: rgba($white,.8);
    white-space: nowrap; }
  &__socials {
    height: 15px;
    display: flex;
    justify-content: center; }
  &__copyright {
    margin-top: 25px;
    font-size: 14px;
    color: rgba($white,.5);
    text-align: center; } }

.icon-close {
  right: 10px;
  font-size: 17px; }

.icon-m-right {
  position: absolute;
  right: 9px;
  top: 11px; }

.icon-m-left {
  left: 6px;
  width: 20px;
  height: 20px;
  fill: black; }
