html,
body {
  height: 100%; }
body {
  min-width: 1024px;
  @include f;
  font-size: 14px;
  line-height: 1.3;
  @include m {
    min-width: 320px; } }
body,
button,
input,
textarea,
select {
  @include f;
  font-size: 14px; }
button {
  background: transparent; }
a {
  text-decoration: none; }



